/** @jsx jsx */
import { jsx, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { ReactComponent as StopIcon } from 'assets/icons/stop.svg'

type ErrorProps = {
    error: {
        errors: object,
        title?: string,
        status: number
    }
}

const Frame = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontSize: '12px',
        padding: '12px 14px'
    }
})

const Row = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    }
})

const Title = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontWeight: 600,
        marginRight: 20,
        minWidth: 60,
        textAlign: 'left'
    }
})

const Separator = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        borderBottom: `1px solid ${theme?.colors.color2}`,
        marginTop: 10
    }
})

const Body = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {

    }
})

const Header = styled.h3(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        margin: 0
    }
})

function Error({
    error
}: ErrorProps) {

    const kvPair = Object.entries(error.errors || [])

    const result = kvPair.map((x, i) => {
        const title = x[0]
        const info = x[1]
        return (
            <Row key={i}>
                <Title>{title}</Title>
                <Body>{
                    !Array.isArray(info) ?
                        info.toString() :
                        info.map((x, i) => (
                            <div key={i}>{x}</div>
                        ))
                }</Body>
            </Row>
        )
    })
    return (
        <Frame>
            <Header><StopIcon style={{ marginRight: 8 }} /> Ошибка</Header>
            <Row>
                <Title>HTTP</Title>
                <Body>{error.status}</Body>
            </Row>
            {error.title && (
                <Row>
                    <Title>Title</Title>
                    <Body>{error.title}</Body>
                </Row>
            )}
            {kvPair.length !== 0 && (
                <Separator />
            )}
            {result}
        </Frame>
    )
}

export {
    Error
}