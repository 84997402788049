export const small = '@media (min-width: 720px)'
export const medium = '@media (min-width: 1280px)'
export const large = '@media (min-width: 1920px)'

export const theme = {
    mq: {
        small,
        medium,
        large,
    }
}

const pinColors = {
    point1: '#8CB917',
    point2: '#A2C617',
    point3: '#BEC617',
    point4: '#FFCC33',
    point5: '#FFA33B',
    point6: '#FF7344',
    point7: '#D63B50',
    point8: '#AD2D55',
    point9: '#821E5A',
    point10: '#821E5A',
    empty: '#ADB2BC',
}

const magicAirProTheme = {
    colors: {
        color1: '#3A3A3A',
        color2: '#979797',
        color3: '#D7D9DD',
        color4: '#F6F7FB',
        color5: '#3D4553',
        color6: '#F7F7FA',
        color7: '#7F7F7F',
        color8: '#E9EFF7',
        color9: '#676767',
        color10: '#505F79',
        color11: '#F1F1F1',
        color12: '#BABABA',
        color13: '#4A4A4A',
        color14: '#ECEFF5',
        color15: '#8B7EE0',
        color16: '#665E9B',
        color17: '#FBFBFB',
        color18: '#A196ED',
        color19: '#ADB2BC',
        color20: '#95A0B3',
        color21: '#353B41',
        color22: '#FFFFFF',
        color23: '#515151',
        color24: '#BCC1CB',
        color25: '#e9eff7ab',
        color26: '#FD484A',
        color27: '#8A66EC',
        color28: '#A1C615',
        color29: '#FFCC32',
        color30: '#6945D0',
        color31: '#8176CD',
        color32: '#7B54E8',
        color33: '#3F3A64',
        color34: '#5437A4',
        color35: '#CBC5EF',
        color36: '#B1A7CD',
        color37: '#93BF0D',
        color38: '#8CB917',
        color39: '#E6ECF2',
        color40: '#828CA0',
        color41: '#444950',
        color42: '#D0D6E1',
        color43: '#5897FF',
        color44: '#DDE6EF',
        color45: '#EAEDF5',
        color46: '#FF6262',
        color47: '#91BE0F',
        color48: '#FFEB32',
        color49: '#B9BED8',
        color50: '#F7F7F7',
        color51: '#F2F2F2',
        color52: '#0BD988',
        color53: '#C8D8E9',
        color54: '#D5D2E4',
        color55: '#F5F7FA',
        color56: '#FF8A00',
        color57: '#4798F6',
        color58: '#aec9fa',
        color59: '#F8F8F8',
    },
    pinColors,
    spacing: (space: number) => `${space}px`,
    fns: {
        getPinColor(number: number) {
            switch (number) {
                case 1: return pinColors.point1
                case 2: return pinColors.point2
                case 3: return pinColors.point3
                case 4: return pinColors.point4
                case 5: return pinColors.point5
                case 6: return pinColors.point6
                case 7: return pinColors.point7
                case 8: return pinColors.point8
                case 9: return pinColors.point9
                case 10: return pinColors.point10
                default: return pinColors.empty
            }
        },
        shadeColor(color: string, percent: number) {
            return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + percent)).toString(16)).substr(-2))
        },
        intentToColor(intent?: IntentType, defaultColor?: string): string {
            switch (intent) {
                case undefined: return defaultColor || 'inherit'
                case 'none': return defaultColor || 'inherit'
                case 'danger': return magicAirProTheme.colors.color46
                case 'primary': return magicAirProTheme.colors.color32
                case 'success': return magicAirProTheme.colors.color38
                default: return magicAirProTheme.colors.color7
            }
        }
    },
    mq: {
        small,
        medium,
        large,
    },
    fontWeight: {
        medium: 500
    },
    letterSpacing: {
        xSmall: '-0.4px',
        small: '-0.525px',
        medium: '-0.72px'
    },
    modules: {
        map: {
            leftAreaWidth: 420
        }
    }
}

export {
    magicAirProTheme
}