import React from 'react'
import { isWithinInterval, addMinutes, startOfDay } from 'date-fns'
import { get, defaultTo } from 'lodash-es'
import { Config } from './config'
import { toast } from 'react-toastify'

import {
    ServerForbiddenToasterMessage,
    defaultToasterOptions
} from 'components/toaster-messages'

enum TIMELINE_MODE {
    HISTORY = 'history',
    LIVE = 'live'
}

enum SELECTION_ROOM_MODE {
    SINGLE = 'single',
    MULTIPLE = 'multiple'
}

function random(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min)
}

function closest(arr: any, target: any) {
    if (!(arr) || arr.length == 0)
        return null;
    if (arr.length == 1)
        return arr[0];

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > target) {
            var p = arr[i - 1];
            var c = arr[i]
            return Math.abs(p - target) < Math.abs(c - target) ? p : c;
        }
    }
    return arr[arr.length - 1];
}

function splitWithDot(values: string[]) {
    return values.filter(x => x !== null && x !== undefined && x !== '').join(' • ')
}

function SplitWithDot({ values }: { values: React.ReactNode[] }) {
    const filtering = values.filter(x => x !== null && x !== undefined && x !== '')
    const jsx = filtering.map((x, i, arr) => {
        return (
            <React.Fragment
                key={i}
            >
                {x}
                {arr.length !== i + 1 ? <React.Fragment>&nbsp;•&nbsp;</React.Fragment> : <React.Fragment />}
            </React.Fragment>
        )
    })
    return <React.Fragment>{jsx}</React.Fragment>
}

function refreshPage() {
    // TODO сделать хук который переключает даты если now В интервале нового дня
    // onRangeDateChange={({ from, to }) => {
    //     historySensorDataProps.setDatesRange({ from: startOfDay(from), to: isToday(to) ? to : endOfDay(to) })
    //     chartTimeIntervalProps.clearFilter()
    // }}

    setInterval(() => {
        const now = new Date()
        const startOfNow = startOfDay(now)

        const startOfRange = startOfNow
        const endOfRange = addMinutes(startOfNow, 7)

        const isInRange = isWithinInterval(
            now,
            { start: startOfRange, end: endOfRange }
        )

        if (isInRange) {
            window.location.reload()
        }
    }, 6 * 60 * 1000)
}

function searchItemsFn<T>(items: T[], text: string, searchProps: string[]) {
    const _text = text.toString().toLowerCase().split(' ')
    return items.filter(function (item) {
        return _text.every(function (textChunk) {
            return searchProps.some(prop => {
                return defaultTo(get(item, prop), '').toLowerCase().indexOf(textChunk) > -1
            })
        })
    })
}

function WrapWithQuotes({ children }: { children: React.ReactNode }) {
    return <React.Fragment>&#171;{children}&#187;</React.Fragment>
}

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

function notConcurrent<T>(proc: () => PromiseLike<T>) {
    let inFlight: Promise<T> | false = false

    return () => {
        if (!inFlight) {
            inFlight = (async () => {
                try {
                    return await proc()
                } finally {
                    inFlight = false
                }
            })()
        }
        return inFlight
    }
}

function nameof<T>(name: Extract<keyof T, string>): string {
    return name
}

class TimezoneSource {

    static getItem(shiftMinutes: number): typeof TimezoneSource.data[0] {
        return TimezoneSource.data.find(x => x.value === shiftMinutes) ?? TimezoneSource.data.find(x => x.value === 0)!
    }

    static data = [
        {
            value: -12 * 60,
            text: "(GMT -12:00) Eniwetok, Kwajalein",
            shortText: "GMT -12:00"
        },
        {
            value: -11 * 60,
            text: "(GMT -11:00) Midway Island, Samoa",
            shortText: "GMT -11:00"
        },
        {
            value: -10 * 60,
            text: "(GMT -10:00) Hawaii",
            shortText: "GMT -10:00"
        },
        {
            value: -9 * 60,
            text: "(GMT -9:00) Alaska",
            shortText: "GMT -9:00"
        },
        {
            value: -8 * 60,
            text: "(GMT -8:00) Pacific Time (US & Canada)",
            shortText: "GMT -8:00"
        },
        {
            value: -7 * 60,
            text: "(GMT -7:00) Mountain Time (US & Canada)",
            shortText: "GMT -7:00"
        },
        {
            value: -6 * 60,
            text: "(GMT -6:00) Central Time (US & Canada), Mexico City",
            shortText: "GMT -6:00"
        },
        {
            value: -5 * 60,
            text: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
            shortText: "GMT -5:00"
        },
        {
            value: -4 * 60,
            text: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
            shortText: "GMT -4:00"
        },
        {
            value: -3.5 * 60,
            text: "(GMT -3:30) Newfoundland",
            shortText: "GMT -3:30"
        },
        {
            value: -3 * 60,
            text: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
            shortText: "GMT -3:00"
        },
        {
            value: -2 * 60,
            text: "(GMT -2:00) Mid-Atlantic",
            shortText: "GMT -2:00"
        },
        {
            value: -1 * 60,
            text: "(GMT -1:00) Azores, Cape Verde Islands",
            shortText: "GMT -1:00"
        },
        {
            value: 0,
            text: "(GMT +0:00) Western Europe Time, London, Lisbon, Casablanca",
            shortText: "GMT +0:00"
        },
        {
            value: 1 * 60,
            text: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
            shortText: "GMT +1:00"
        },
        {
            value: 2 * 60,
            text: "(GMT +2:00) Kaliningrad, South Africa",
            shortText: "GMT +2:00"
        },
        {
            value: 3 * 60,
            text: "(GMT +3:00) Moscow, St. Petersburg, Baghdad, Riyadh",
            shortText: "GMT +3:00"
        },
        {
            value: 3.5 * 60,
            text: "(GMT +3:30) Tehran",
            shortText: "GMT +3:30"
        },
        {
            value: 4 * 60,
            text: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
            shortText: "GMT +4:00"
        },
        {
            value: 4.5 * 60,
            text: "(GMT +4:30) Kabul",
            shortText: "GMT +4:30"
        },
        {
            value: 5 * 60,
            text: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
            shortText: "GMT +5:00"
        },
        {
            value: 5.5 * 60,
            text: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
            shortText: "GMT +5:30"
        },
        {
            value: 5.75 * 60,
            text: "(GMT +5:45) Kathmandu",
            shortText: "GMT +5:45"
        },
        {
            value: 6 * 60,
            text: "(GMT +6:00) Almaty, Dhaka, Colombo",
            shortText: "GMT +6:00"
        },
        {
            value: 7 * 60,
            text: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
            shortText: "GMT +7:00"
        },
        {
            value: 8 * 60,
            text: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
            shortText: "GMT +8:00"
        },
        {
            value: 9 * 60,
            text: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
            shortText: "GMT +9:00"
        },
        {
            value: 9.5 * 60,
            text: "(GMT +9:30) Adelaide, Darwin",
            shortText: "GMT +9:30"
        },
        {
            value: 10 * 60,
            text: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
            shortText: "GMT +10:00"
        },
        {
            value: 11 * 60,
            text: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
            shortText: "GMT +11:00"
        },
        {
            value: 12 * 60,
            text: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
            shortText: "GMT +12:00"
        }
    ]
}

function downloadFile(bytes: File, contentType: string, name: string) {
    const url = window.URL.createObjectURL(
        new Blob([bytes], { type: contentType }),
    )

    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', name)

    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
}

function getFileExtensionByContentType(contentType: string) {
    switch (contentType) {
        case 'image/jpeg':
            return 'jpg'
        case 'image/png':
            return 'png'
        case 'image/bmp':
            return 'bmp'

        case 'application/pdf':
            return 'pdf'

        case 'text/csv':
            return 'csv'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'docx'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xlsx'
        case 'application/vnd.ms-excel':
            return 'xls'

        default:
            throw new Error("unsupported content type");
    }
}

function getFileName(contentType: string, contentDisposition: string) {
    const fileNameRegExp = /filename\*[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/g

    const fileNameFromContent = contentDisposition
        ? contentDisposition.match(fileNameRegExp)
        : null

    const result = (fileNameFromContent && fileNameFromContent?.length !== 0)
        ? (fileNameFromContent[0]).replace("filename*=UTF-8''", '')
        : `mapro_file_${new Date().toLocaleDateString()}.${getFileExtensionByContentType(contentType)}`

    return decodeURI(result)
}

function arrayToQueryParams(arr: (string | number)[], name: string): string {
    let params = new URLSearchParams()
    arr.forEach(x => {
        params.append(name, x.toString())
    })

    return params.toString()
}

function secondsToDhms(seconds: number, options: { isShort?: boolean } = {}) {
    seconds = Number(seconds)
    var d = Math.floor(seconds / (3600 * 24))
    var h = Math.floor(seconds % (3600 * 24) / 3600)
    var m = Math.floor(seconds % 3600 / 60)
    var s = Math.floor(seconds % 60)

    var dDisplay = d > 0 ? d + (d == 1 ? " д., " : " д., ") : ""
    var hDisplay = h > 0 ? h + (h == 1 ? " ч., " : " ч., ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " м., " : " м., ") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? " с." : " с.") : ""
    return (
        dDisplay
        + hDisplay
        + mDisplay
        //+ sDisplay
    ).replace(/,\s*$/, "")
}

function getAttachmentUrl(id: NullOrUndefined<number>): string {
    return `${Config.options.REACT_APP_API_URL}/attachments/${id}`
}

function is403Error(e: any): boolean {
    if (e.response.status === 403) {
        toast.warning(<ServerForbiddenToasterMessage />, defaultToasterOptions)
        return true
    }

    return false
}

function getCentralPinId(pins: any[]): number | null {

    const pinsWithLocations = pins.filter(x => x.placement.location !== null)
    if (!pinsWithLocations || pinsWithLocations.length === 0) {
        return null
    }

    // const for svg maps
    const width = 1024
    const height = 768

    let stepX = 24
    let stepY = 32

    let pinsInCenter = new Array<any>()

    while (pinsInCenter.length === 0 && stepY <= width && stepX <= height) {
        // eslint-disable-next-line no-loop-func
        pinsWithLocations.forEach(x => {
            if (
                x.placement.location.x <= height / 2 + stepX
                && x.placement.location.x >= height / 2 - stepX
                && x.placement.location.y <= width / 2 + stepY
                && x.placement.location.y >= width / 2 - stepY
            ) {
                pinsInCenter.push(x)
            }
        })

        stepX += 24
        stepY += 32
    }

    return pinsInCenter?.[0].id
}

async function identityErrorDescribeHandler(e: any) {
    const response = await e.body.pipeThrough(new TextDecoderStream()).getReader().read()
    const { errors } = JSON.parse(response.value)
    if (!errors) return null
    return Object.keys(errors).map(x => {
        if (x === 'InvalidToken') return 'Invalid code.'
        return errors[x]
    }).flat().join(" ")
}

function stringToHslColor(str: string, s: string = '50', l: string = '80') {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export {
    secondsToDhms,
    TIMELINE_MODE,
    SELECTION_ROOM_MODE,
    arrayToQueryParams,
    random,
    closest,
    splitWithDot,
    SplitWithDot,
    refreshPage,
    searchItemsFn,
    WrapWithQuotes,
    isLocalhost,
    notConcurrent,
    nameof,
    TimezoneSource,
    downloadFile,
    getFileName,
    getAttachmentUrl,
    is403Error,
    identityErrorDescribeHandler,
    getCentralPinId,
    stringToHslColor
}