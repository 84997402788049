import React from 'react'
import 'normalize.css'

import { useAuth } from 'context/auth-context'
import FullPageSpinner from 'components/full-page-spinner'

const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './authenticated-app'))
const UnauthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './unauthenticated-app'))

export default function App() {
    const { user } = useAuth()
    return (
        <React.Suspense fallback={<FullPageSpinner />}>
            {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </React.Suspense>
    )
}