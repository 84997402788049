/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { NavLink } from 'components/nav-link'
import { Copyright } from 'components/copyright'
import { Button } from 'components/button'
import { InputGroup } from 'components/input-group'
import { Input2 } from 'components/input2'

import {
    Frame,
    Body,
    Title,
    SubTitle,
    CommonError,
    BackToLogin,
    CopyrightWrapper,
    FormValueBlock
} from '../../dependencies/styles-mobile'
import { useModule } from './use-module'

function Mobile() {
    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isValid,
            isSubmitting
        },
        getIntent,
        onSubmit,
        backToLoginUrl
    } = useModule()

    const { formatMessage } = useIntl()

    return (
        <Frame>
            <Body>
                <Title>
                    <FormattedMessage id='app.module.restore.title' />
                </Title>
                <SubTitle>
                    <FormattedMessage id='app.module.restore.email.subtitle' />
                </SubTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormValueBlock>
                        <InputGroup
                            intent={errors.email ? 'danger' : 'none'}
                            bottomHint={errors.email?.message}
                        >
                            <Input2
                                autoComplete='off'
                                label={<FormattedMessage id='app.common.form.email' />}
                                intent={getIntent(errors.common?.message || errors.email?.message)}
                                placeholder={formatMessage({ id: 'app.common.form.email' })}
                                disabled={isSubmitting}
                                {...register('email')}
                            />
                        </InputGroup>
                    </FormValueBlock>

                    <CommonError>
                        {errors.common?.message}
                    </CommonError>

                    <Button
                        disabled={!isValid}
                        loading={isSubmitting}
                        intent='primary'
                        variant='contained'
                        text={<FormattedMessage id='app.common.form.next' />}
                        width='100%'
                        height={'58px'}
                        buttonProps={{ type: 'submit' }}
                    />
                </form>

                <BackToLogin>
                    <NavLink
                        to={backToLoginUrl}
                        underline='none'
                        variant='primary'
                        text={<FormattedMessage id='app.module.restore.backToLogin' />}
                    />
                </BackToLogin>

                <CopyrightWrapper>
                    <Copyright variant='2' />
                </CopyrightWrapper>
            </Body>
        </Frame >
    )
}

export {
    Mobile
}