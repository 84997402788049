/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useMedia } from 'context/media-context'

import { Desktop } from './dependencies/desktop'
import { Mobile } from './dependencies/mobile'

export default function Email() {
    const { isDesktop } = useMedia()
    return isDesktop ? <Desktop /> : <Mobile />
}