/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import Email from 'modules/restore/email'
import ConfirmPassword from 'modules/restore/confirm-password'
import { Loader } from 'components/loader'
import { ReactComponent as BubblesAndCloudsIcon } from 'assets/icons/bubbles_and_clouds.svg'

export function EmailScreen() {
    return (
        <ErrorBoundary
            FallbackComponent={() => (
                <Loader
                    status={'error'}
                    text={'app.module.restore.errorBoundaryMessage'}
                    image={<BubblesAndCloudsIcon />}
                    spinnerSize={48}
                />
            )}
        >
            <Email />
        </ErrorBoundary>
    )
}

export function ConfirmPasswordScreen() {
    return (
        <ErrorBoundary
            FallbackComponent={() => (
                <Loader
                    status={'error'}
                    text={'app.module.restore.errorBoundaryMessage'}
                    image={<BubblesAndCloudsIcon />}
                    spinnerSize={48}
                />
            )}
        >
            <ConfirmPassword />
        </ErrorBoundary>
    )
}