/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import {
    EmailScreen,
    ConfirmPasswordScreen,
} from 'screens/restore'

export function RestorePasswordRoutes({ children }: { children: React.ReactNode }) {
    return (
        <Routes>
            <Route path="/restore" element={<EmailScreen />} />
            <Route path="/restore/confirm-password" element={<ConfirmPasswordScreen />} />
            <Route path="*" element={<React.Fragment>{children}</React.Fragment>} />
        </Routes>
    )
}