
import React from 'react'

import { Spinner } from 'components/spinner'

export default function FullPageSpinner() {
    return (
        <div style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
        }} >
            <Spinner />
        </div>
    )
}