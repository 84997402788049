import React from 'react'

const EMPTY = undefined

const useProgressiveImage = (src: string) => {
    const [loadedSrc, setLoadedSrc] = React.useState<string | undefined>(EMPTY)

    React.useEffect(() => {
        if (loadedSrc !== EMPTY) return

        const img = new Image()
        img.src = `${src}?rand=${Math.random()}`
        img.onload = () => setLoadedSrc(img.src)
    }, [])

    function reset() {
        const img = new Image()
        img.src = `${src}?rand=${Math.random()}`
        img.onload = () => setLoadedSrc(img.src)
    }

    return {
        loadedSrc,
        reset
    }
}

export {
    useProgressiveImage
}