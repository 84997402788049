/** @jsx jsx */
import { jsx, Theme } from '@emotion/react'
import { useTheme } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'
import { IntentType } from 'types'

type Input2Props = {
    label: React.ReactNode,
    intent?: IntentType,
    width?: string,
    height?: string,
    type?: 'text' | 'password' | 'number' | 'file',
} & Omit<JSX.IntrinsicElements['input'], 'type' | 'label'>

const StyledComponent = styled.div(({ theme }: {
    theme?: Theme,
}) => {
    return {
        position: 'relative',
        marginTop: 10
    }
})

const StyledLabel = styled.span(({ theme, computedStyles }: {
    theme?: Theme,
    computedStyles?: any
}) => {
    return {
        position: 'absolute',
        top: -8,
        left: 14,

        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px!important',
        lineHeight: '15px',
        letterSpacing: '-0.45px',
        color: theme?.colors.color19,

        ...computedStyles
    }
})

const StyledFieldset = styled.fieldset(({ theme, computedStyles }: {
    theme?: Theme,
    computedStyles?: any
}) => {
    return {
        position: 'absolute',
        top: -8,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'inherit',
        margin: 0,
        padding: '4px 8px 4px',

        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme?.colors.color24,
        borderRadius: 6,
        WebkitTapHighlightColor: 'transparent',

        ...computedStyles
    }
})

const StyledLegend = styled.legend(({ theme }: {
    theme?: Theme,
}) => {
    return {
        visibility: 'hidden',
        fontSize: 12,
        padding: '0px 4px',
    }
})

const StyledInput = styled.input(({ theme, computedStyles }: {
    theme?: Theme,
    computedStyles?: any
}) => {

    return {
        position: 'relative',
        backgroundColor: 'inherit',
        boxSizing: 'border-box', // https://stackoverflow.com/questions/16907518/css-input-with-width-100-goes-outside-parents-bound/16907625
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '-0.72px',
        borderWidth: 0,
        borderRadius: 4,
        color: theme?.colors.color1,
        zIndex: 1,
        
        ':hover': {
        },
        ':disabled': {
            color: theme?.colors.color19,
        },
        
        margin: 1,
        marginTop: 3,
        width: 'calc(100% - 2px)',
        padding: '12px 14px 14px 14px',

        ...computedStyles,
        WebkitAppearance: 'none', // убираем внутреннюю тень на safari
    }
})

function getIntentProps({ intent, theme, disabled }: {
    intent: IntentType,
    theme: any,
    disabled: boolean | undefined
}) {

    if (disabled) {
        return {
            borderColor: theme?.colors.color24,
            color: theme?.colors.color19,
        }
    }

    const defaultProps = {}

    switch (intent) {
        case 'none':
            return defaultProps
        case 'primary':
            return defaultProps
        case 'success':
            return defaultProps
        case 'warning':
            return defaultProps
        case 'danger':
            return {
                borderColor: theme.colors.color26,
                color: theme.colors.color26
            }
    }
}

function getIntentPropsForInput({ intent, theme, disabled }: {
    intent: IntentType,
    theme: any,
    disabled: boolean | undefined
}) {
    const defaultProps = {
        ':focus': {
            '~fieldset': {
                borderColor: theme?.colors.color18,
            },
            '~span': {
                color: theme?.colors.color18,
            }
        },
    }

    switch (intent) {
        case 'none':
            return defaultProps
        case 'primary':
            return defaultProps
        case 'success':
            return defaultProps
        case 'warning':
            return defaultProps
        case 'danger':
            return {
                ':focus': {
                    '~fieldset': {
                        borderColor: theme.colors.color26,
                    },
                    '~span': {
                        color: theme.colors.color26
                    }
                }
            }
    }
}

const Input2 = React.forwardRef<HTMLInputElement, Input2Props>((props, ref) => {

    const {
        label,
        intent = 'none',
        width = 'unset',
        height = 'unset',
        type = 'text',
        ...inputProps
    } = props

    const theme = useTheme()

    const computedInputStyles = {
        height,
        font: 'inherit',
        ...getIntentPropsForInput({ intent, theme, disabled: inputProps.disabled }),
    }

    const computedFieldsetStyles = {
        height,
        font: 'inherit',
        ...getIntentProps({ intent, theme, disabled: inputProps.disabled }),
    }

    return (
        <StyledComponent>
            <StyledInput
                ref={ref}
                {...inputProps}
                type={type}
                computedStyles={computedInputStyles}
            />
            <StyledFieldset
                computedStyles={computedFieldsetStyles}
            >
                <StyledLegend>
                    {label}
                </StyledLegend>
            </StyledFieldset>
            <StyledLabel
                computedStyles={computedFieldsetStyles}
            >
                {label}
            </StyledLabel>
        </StyledComponent>
    )
})

export {
    Input2,
}

export type {
    Input2Props
}