/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Card } from 'components/card'
import { NavLink } from 'components/nav-link'
import { Copyright } from 'components/copyright'
import { Button } from 'components/button'
import { AnimatedAppearance } from 'components/animations/animated-appearance'
import { Config } from 'utils/config'
import { useProgressiveImage } from 'utils/hooks/use-progressive-image'
import { InputGroup } from 'components/input-group'
import { Input2 } from 'components/input2'

import {
    Frame,
    Div100Percent,
    CardWrapper,
    Title,
    SubTitle,
    StyledForm,
    CopyrightWrapper,
    CommonError,
    FormValueBlock
} from '../../dependencies/styles-desktop'
import { useModule } from './use-module'

function Desktop() {
    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isValid,
            isSubmitting
        },
        getIntent,
        onSubmit,
        backToLoginUrl
    } = useModule()

    const { formatMessage } = useIntl()
    const { loadedSrc } = useProgressiveImage(`${Config.options.REACT_APP_API_URL}/attachments/logo`)

    return (
        <Frame style={{ backgroundImage: `url(${loadedSrc})` }}>
            <Div100Percent>
                <CardWrapper>
                    <AnimatedAppearance
                        direction='bottom'
                        duration={0.5}
                    >
                        <Card borderRadius={32}>
                            <Title>
                                <FormattedMessage id='app.module.restore.title' />
                            </Title>
                            <SubTitle>
                                <FormattedMessage id='app.module.restore.passwordConfirm.subtitle' />
                            </SubTitle>

                            <StyledForm>
                                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                                    <FormValueBlock>
                                        <InputGroup
                                            intent={errors.recoveryCode ? 'danger' : 'none'}
                                            bottomHint={errors.recoveryCode?.message}
                                        >
                                            <Input2
                                                autoComplete='off'
                                                label={<FormattedMessage id='app.common.form.recoveryCode' />}
                                                intent={getIntent(errors.common?.message || errors.recoveryCode?.message)}
                                                {...register('recoveryCode')}
                                                type='password'
                                            />
                                        </InputGroup>
                                    </FormValueBlock>

                                    <FormValueBlock>
                                        <InputGroup
                                            intent={errors.newPassword ? 'danger' : 'none'}
                                            bottomHint={errors.newPassword?.message}
                                        >
                                            <Input2
                                                autoComplete='off'
                                                type='password'
                                                label={<FormattedMessage id='app.common.form.newPassword' />}
                                                intent={getIntent(errors.common?.message || errors.newPassword?.message)}
                                                {...register('newPassword')}
                                            />
                                        </InputGroup>
                                    </FormValueBlock>

                                    <FormValueBlock>
                                        <InputGroup
                                            intent={errors.newPasswordConfirm ? 'danger' : 'none'}
                                            bottomHint={errors.newPasswordConfirm?.message}
                                        >
                                            <Input2
                                                autoComplete='off'
                                                type='password'
                                                label={<FormattedMessage id='app.common.form.confirmPassword' />}
                                                intent={getIntent(errors.common?.message || errors.newPasswordConfirm?.message)}
                                                {...register('newPasswordConfirm')}
                                            />
                                        </InputGroup>
                                    </FormValueBlock>

                                    <CommonError>
                                        {errors.common?.message}
                                    </CommonError>

                                    <div style={{ marginBottom: 32, width: '100%' }}>
                                        <Button
                                            disabled={!isValid}
                                            loading={isSubmitting}
                                            size='large'
                                            intent='primary'
                                            variant='contained'
                                            text={<FormattedMessage id='app.common.form.save' />}
                                            width='100%'
                                            buttonProps={{ type: 'submit' }}
                                        />
                                    </div>
                                </form>

                                <NavLink
                                    to={backToLoginUrl}
                                    underline='none'
                                    variant='primary'
                                    text={<FormattedMessage id='app.module.restore.backToLogin' />}
                                />
                            </StyledForm>
                        </Card>
                    </AnimatedAppearance>
                </CardWrapper>
            </Div100Percent>

            <CopyrightWrapper>
                <Copyright variant='1' />
            </CopyrightWrapper>
        </Frame>
    )
}

export {
    Desktop
}