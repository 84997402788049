/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

type Direction = 'top' | 'right' | 'bottom' | 'left'

type AnimatedAppearanceProps = {
    show?: boolean
    children: React.ReactNode,
    direction: Direction,
    duration?: number,
    delay?: number,
    shift?: number,
    containerCssProps?: React.CSSProperties
}

const getMotionProps = (direction: Direction, shift: number) => {
    switch (direction) {
        case "bottom": {
            return {
                initial: { opacity: 0, y: shift },
                animate: { opacity: 1, y: 0 }
            }
        }
        case "top": {
            return {
                initial: { opacity: 0, y: -shift },
                animate: { opacity: 1, y: 0 }
            }
        }
        case "left": {
            return {
                initial: { opacity: 0, x: -shift },
                animate: { opacity: 1, x: 0 }
            }
        }
        case "right": {
            return {
                initial: { opacity: 0, x: shift },
                animate: { opacity: 1, x: 0 }
            }
        }
    }
}

function AnimatedAppearance({
    children,
    duration = .2,
    direction,
    shift = 20,
    delay = 0,
    show = true,
    containerCssProps
}: AnimatedAppearanceProps) {
    return (
        <AnimatePresence exitBeforeEnter>
            {show && (
                <motion.div
                    {...getMotionProps(direction, shift)}
                    exit={{ opacity: 0 }}
                    transition={{ duration, delay, type: 'tween' }}
                    style={containerCssProps}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export {
    AnimatedAppearance
}