/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useTheme } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'components/spinner'

import { IntentType, SizeType } from 'types'

type VariantType = 'contained' | 'minimal'

interface ButtonProps {
    size?: SizeType,
    intent?: IntentType,
    className?: string,

    onClick?: React.MouseEventHandler<HTMLButtonElement>,

    width?: string,
    height?: string,
    loading?: boolean,
    disabled?: boolean,

    //   width: number | string;
    //   height: number;


    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
    variant?: VariantType,

    text?: React.ReactNode,
    buttonProps?: JSX.IntrinsicElements['button'],
    style?: React.CSSProperties
}

const StyledText = styled.span(({ theme, visibility }: {
    theme?: any,
    visibility: any
}) => {

    return {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: visibility
    }
})

const StyledLoader = styled.span(({ theme }: {
    theme?: any
}) => {

    return {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

const StyledButton = styled.button(({ theme, computedStyles, ...otherProps }: {
    theme?: any,
    computedStyles?: any,
}) => {

    return {
        position: 'relative',
        cursor: 'pointer',
        borderRadius: 6,
        ...computedStyles,
        WebkitTapHighlightColor: 'transparent',
    }
})

function getSizeProps({ size }: { size: SizeType }) {
    switch (size) {
        case 'small':
            return {
                fontSize: '14px',
                lineHeight: '16px',
                padding: '10px 30px'
            }
        case 'medium':
            return {
                fontSize: '16px',
                lineHeight: '20px',
                padding: '12px 36px'
            }
        case 'large':
            return {
                fontSize: '18px',
                lineHeight: '24px',
                padding: '16px 48px'
            }
    }

}

const hoverBoxShadow = '0px 2px 4px rgba(0, 0, 0, 0.101166)'
const activeBoxShadow = '0px 2px 4px rgba(0, 0, 0, 0.101166)'
const disabledBoxShadow = '0px 2px 4px rgba(0, 0, 0, 0.101166)'

function getIntentProps({
    intent,
    variant,
    disabled,
    theme,
}: {
    intent: IntentType,
    variant: VariantType,
    disabled: boolean,
    theme: any,
}) {

    switch (intent) {
        case 'none':
            return {
                background: variant === 'contained' ? theme.colors.color39 : 'inherit',
                color: variant === 'contained' ? theme.colors.color40 : theme.colors.color20,
                ':hover': {
                    background: variant === 'contained' ? theme.colors.color53 : 'inherit',
                    boxShadow: variant === 'contained' ? hoverBoxShadow : 'none'
                },
                ':active': {
                    background: variant === 'contained' ? theme?.fns.shadeColor(theme!.colors.color53, -10) : 'inherit',
                    boxShadow: variant === 'contained' ? activeBoxShadow : 'none'
                },
                ':disabled': {
                    cursor: 'not-allowed',
                    color: theme?.fns.shadeColor(theme!.colors.color53, -10),
                    background: variant === 'contained' ? theme.colors.color51 : 'inherit',
                    boxShadow: variant === 'contained' ? disabledBoxShadow : 'none'
                },
            }
        case 'primary':
            return {
                color: variant === 'contained' ? theme.colors.color22 : theme.colors.color16,
                background: variant === 'contained' ? theme.colors.color16 : 'none',
                ':hover': {
                    color: variant === 'contained' ? theme.colors.color22 : theme.colors.color16,
                    background: variant === 'contained' ? theme.colors.color31 : 'inherit',
                    boxShadow: variant === 'contained' ? hoverBoxShadow : 'none'
                },
                ':active': {
                    color: variant === 'contained' ? theme.colors.color22 : theme.colors.color16,
                    background: variant === 'contained' ? theme?.fns.shadeColor(theme!.colors.color31, -10) : 'inherit',
                    boxShadow: variant === 'contained' ? activeBoxShadow : 'none'
                },
                ':disabled': {
                    cursor: 'not-allowed',
                    color: variant === 'contained' ? theme?.fns.shadeColor(theme!.colors.color22, -10) : theme?.fns.shadeColor(theme!.colors.color16, 50),
                    background: variant === 'contained' ? theme.colors.color54 : 'inherit',
                    boxShadow: variant === 'contained' ? disabledBoxShadow : 'none'
                },
            }
        case 'success':
            return {
                color: variant === 'contained' ? theme.colors.color22 : 'green',
                backgroundColor: variant === 'contained' ? 'green' : 'inherit',
                ':hover': {
                    background: variant === 'contained' ? '#016c01' : 'inherit',
                    boxShadow: variant === 'contained' ? hoverBoxShadow : 'none'
                },
                ':active': {
                    background: variant === 'contained' ? '#006400' : 'inherit',
                    boxShadow: variant === 'contained' ? activeBoxShadow : 'none'
                },
                ':disabled': {
                    cursor: 'not-allowed',
                    filter: 'opacity(0.5)',
                },
            }
        case 'warning':
            return {
                color: variant === 'contained' ? theme.colors.color22 : 'orange',
                backgroundColor: variant === 'contained' ? 'orange' : 'inherit',
                ':hover': {
                    background: variant === 'contained' ? 'orange' : 'inherit',
                    boxShadow: variant === 'contained' ? hoverBoxShadow : 'none'
                },
                ':active': {
                    background: variant === 'contained' ? 'orange' : 'inherit',
                    boxShadow: variant === 'contained' ? activeBoxShadow : 'none'
                },
                ':disabled': {
                    cursor: 'not-allowed',
                    filter: 'opacity(0.5)',
                },
            }
        case 'danger':
            return {
                color: variant === 'contained' ? theme.colors.color22 : theme.colors.color26,
                backgroundColor: variant === 'contained' ? theme.colors.color26 : 'inherit',
                ':hover': {
                    background: variant === 'contained' ? theme.colors.color26 : theme?.fns.shadeColor(theme!.colors.color26, 170),
                    boxShadow: variant === 'contained' ? hoverBoxShadow : 'none'
                },
                ':active': {
                    background: variant === 'contained' ? theme.colors.color26 : theme?.fns.shadeColor(theme!.colors.color26, 160),
                    boxShadow: variant === 'contained' ? activeBoxShadow : 'none'
                },
                ':disabled': {
                    cursor: 'not-allowed',
                    filter: 'opacity(0.5)',
                },
            }
    }
}


function Button({
    size = 'medium',
    intent = 'none',

    onClick,

    width = 'unset',
    height = 'unset',
    loading = false,
    disabled = false,
    className,

    startIcon = null,           // пока не реализуем
    endIcon = null,             // пока не реализуем
    variant = 'contained',      // пока не реализуем   

    text,
    buttonProps,
    style
}: ButtonProps) {

    const theme = useTheme()
    const computedDisabled = loading || disabled
    let spinnerSize

    switch (size) {
        case 'small':
            spinnerSize = 16
            break
        case 'medium':
            spinnerSize = 18
            break
        case 'large':
            spinnerSize = 22
            break
    }

    const computedStyles = {
        width,
        height,
        border: 'none',
        font: 'inherit',
        ...getSizeProps({ size }),
        ...getIntentProps({ intent, variant, theme, disabled: computedDisabled }),
        ...style
    }

    return (
        <StyledButton
            onClick={onClick}
            disabled={computedDisabled}
            {...buttonProps}
            computedStyles={computedStyles}
            className={className}
        >
            {/* {startIcon} */}

            {loading && (
                <StyledLoader>
                    <Spinner
                        delay={0}
                        color={variant === 'contained' ? (theme as any).colors.color22 : (theme as any).colors.color12}
                        size={spinnerSize}
                    />
                </StyledLoader>
            )}

            <StyledText
                visibility={loading ? 'hidden' : 'visible'}>
                {text}
            </StyledText>

            {/* {endIcon} */}
        </StyledButton>
    )
}

export {
    Button
}