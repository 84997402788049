import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import { AppProviders } from 'context/app-providers'
import App from './app'
import 'utils/initialize-events-hub'
import { refreshPage } from 'utils'
import { Config } from 'utils/config'
import { ConfigWarning } from 'modules/config-warning'

refreshPage()

const healthMessages = Config.performHealthCheck()

ReactDOM.render(healthMessages.length === 0 ? (
    <AppProviders>
        <App />
    </AppProviders>
) : <ConfigWarning messages={healthMessages} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
