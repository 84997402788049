/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Spinner } from '../spinner'

type LoadingType = 'loading' | 'error' | 'fetching'

const showLoader = (status: string) => status !== 'success'

const StyledPlacementsNoData = styled.div(({
    theme
}: {
    theme?: any,
}) => ({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: theme.letterSpacing.medium,
    color: theme.colors.color19,
    width: '100%'
}))

const FillWrapper = styled.div(({
    theme
}: {
    theme?: any,
}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap'
}))

const Loader = ({
    status,
    text,
    image,
    spinnerSize,
    fill = false
}: {
    status: LoadingType,
    text?: string,
    image?: React.ReactNode,
    spinnerSize: number,
    fill?: boolean
}) => {
    const Wrapper = fill ? FillWrapper : React.Fragment

    if (status === 'error') return (
        <FillWrapper>
            {text && (
                <StyledPlacementsNoData>
                    <FormattedMessage id={text} />
                </StyledPlacementsNoData>
            )}
            {image}
        </FillWrapper>
    )

    if (status === 'loading' || status === 'fetching') {
        return (
            <Wrapper>
                <Spinner size={spinnerSize} />
            </Wrapper>
        )
    }

    return <React.Fragment />
}

export {
    Loader,
    showLoader
}

export type {
    LoadingType
}