import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@emotion/react'
import { QueryParamProvider } from 'use-query-params'
import { ToastContainer, cssTransition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'theme/toaster-styles.css'

import { AppIntlProvider, LOCALE } from 'localization/app-intl-context'
import { RouteAdapter } from 'utils/route-adapter'
import { AuthProvider } from 'context/auth-context'
import { SignalRConnectionProvider } from 'context/signalr-context'
import { useSignalR } from 'context/signalr-context/use-signar'
import { magicAirProTheme } from 'theme'
import GlobalStyles from 'theme/global-style'
import { MediaProvider } from 'context/media-context'
import { queryClient } from 'utils/react-query-config'
import { Config } from 'utils/config'
import { RestorePasswordRoutes } from 'app-routes/restore-password-routes'

const Opacity = cssTransition({
    enter: 'toaster-enter',
    exit: 'toaster-exit',
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
})

const signalRStringConnection = (Config.options.REACT_APP_API_URL as string) + '/hub'

function AppProviders({ children }: { children: React.ReactChild }) {
    return (
        <MediaProvider>
            <ThemeProvider theme={magicAirProTheme}>
                <GlobalStyles />
                <ToastContainer
                    toastClassName='custom-toast'
                    position='top-center'
                    autoClose={1000}
                    closeButton={false}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    pauseOnHover
                    transition={Opacity}
                />
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <SignalRConnectionProvider
                            connection={useSignalR({ url: signalRStringConnection })}
                        >
                            <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
                                <AppIntlProvider>
                                    <RestorePasswordRoutes>
                                        <AuthProvider>
                                            {children}
                                        </AuthProvider>
                                    </RestorePasswordRoutes>
                                </AppIntlProvider>
                            </QueryParamProvider>
                        </SignalRConnectionProvider>
                    </Router>
                </QueryClientProvider>
            </ThemeProvider>
        </MediaProvider>
    )
}

export {
    AppProviders
}