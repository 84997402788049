import React from 'react'
import { IntlProvider } from 'react-intl'
import { LOCALE, chooseMessages } from './helpers'
import { useUpdateHighchartsGlobally } from './dependencies/use-update-highcharts-globally'

const AppIntlContext = React.createContext()
AppIntlContext.displayName = 'AppIntlContext'

const languageToken = 'userLanguage'

function getInitialLocale() {
    const storedLocale = localStorage.getItem(languageToken)
    return storedLocale || (navigator.language.toLowerCase().includes('ru') ? LOCALE.ruRu : LOCALE.enUS)
}

function AppIntlProvider({
    children
}) {
    const [currentLocale, setCurrentLocale] = React.useState(getInitialLocale)

    useUpdateHighchartsGlobally({ messages: chooseMessages(currentLocale) })

    React.useEffect(() => {
        localStorage.setItem(languageToken, currentLocale)
    }, [currentLocale])

    return (
        <AppIntlContext.Provider
            value={{
                currentLocale,
                changeLocale: setCurrentLocale
            }}
        >
            <IntlProvider
                messages={chooseMessages(currentLocale)}
                locale={currentLocale}
                key={currentLocale} // https://formatjs.io/docs/react-intl/components#dynamic-language-selection
            >
                {children}
            </IntlProvider >
        </AppIntlContext.Provider>
    )
}

function useAppIntl() {
    const context = React.useContext(AppIntlContext)
    if (context === undefined) {
        throw new Error(`useAppIntl must be used within a AppIntlContext`)
    }
    return context
}

export { AppIntlProvider, useAppIntl, LOCALE }
