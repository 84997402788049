/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import React from 'react'

type VariantProps = '1' | '2'

type CopyrightProps = {
    variant: VariantProps
}

const StyledComponent = styled.p(({ theme, variant }: { theme?: any, variant: VariantProps }) => ({
    margin: 0,
    fontSize: 18,
    lineHeight: '22px',
    letterSpacing: '-0.45px',
    color: variant === '1' ? theme.colors.color22 : theme.colors.color24
}))

function Copyright({
    variant,
}: CopyrightProps) {
    return (
        <StyledComponent variant={variant}>
            <FormattedMessage id='app.copyright' />
        </StyledComponent>
    )
}

export {
    Copyright
}