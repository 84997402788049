/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react'
import React from "react"
import { useTheme } from '@emotion/react'
import { AnimatePresence, motion } from 'framer-motion'

const rotate = keyframes`
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
`

type SpinnerProps = {
    size?: number
    duration?: number
    delay?: number
    color?: string
    show?: boolean
}

export const Spinner = ({
    size = 64,
    duration = 0.2,
    delay = 0.2,
    color = '',
    show = true
}: SpinnerProps) => {
    const theme = useTheme()

    return (
        <AnimatePresence
            exitBeforeEnter
        >
            {show && (
                <motion.svg
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration, delay }}

                    width={size}
                    height={size}
                    viewBox={`0 0 38 38`}
                    xmlns="http://www.w3.org/2000/svg"
                    stroke={color || (theme as any).colors.color28}
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </motion.svg>
            )}
        </AnimatePresence >
    )
}