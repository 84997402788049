/** @jsx jsx */
import { Global, jsx, css } from '@emotion/react'
import { injectGlobal } from '@emotion/css'

import "@reach/dialog/styles.css"
import "@reach/checkbox/styles.css"
import "components/checkbox/dependencies/custom-checkbox-styles.css"
import "components/checkbox/dependencies/custom-checkbox2-styles.css"

// injectGlobal`
// 	html {
// 		 font-family: 'Inter', sans-serif;
// 	}

// 	.Toastify__toast--success {
// 		background: ${magicAirTheme.global.colors.statusOk}!important;
// 	}
// 	.Toastify__toast--warning {
// 		background: ${magicAirTheme.global.colors.statusWarning}!important;
// 	}
// 	.Toastify__toast--error {
// 		background: ${magicAirTheme.global.colors.statusCritical}!important;
// 	}
// `

const styles = css`
body {
	font-family: 'Inter', sans-serif;
	line-height: 1;
	background-color: #F2F2F2;
    width: 100vw;
}

::-webkit-scrollbar {
    width: 8px;
}
 
::-webkit-scrollbar-track {
}
 
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset -1px -1px 5px rgb(183 181 181);
}

*,*:focus,*:hover{
    outline:none;
}

::selection { 
	background: #FFED68;
}

[data-reach-dialog-overlay] {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
    min-height: 100%;
    opacity: 1;
    background: hsla(0, 0%, 0%, 0.33);
    z-index: 3;
    overflow: hidden;
}

[data-reach-dialog-content] {
    width: 100vw;
    height: 100vh;
    background: white;
    outline: none;
    margin: 0px;
    padding: 0px;
}

.leaflet-control-zoom {
	margin-top: 80px!important;
	margin-right: 24px!important;
}
`

export default function GlobalStyles() {
    return (
        <Global
            styles={styles}
        />
    )
}