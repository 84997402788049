import { Theme } from '@emotion/react'
import { useTheme } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'

const Frame = styled.div(({ theme, disabled }: {
    theme?: any,
    disabled: boolean
}) => {

    return {
        position: 'relative',
        color: disabled ? theme.colors.color19 : theme.colors.color1
    }
})

const StyledInputWrapper = styled.div(({ theme }: {
    theme?: any
}) => {

    return {
        marginBottom: 4
    }
})

const BottomHint = styled.div(({ theme, computedStyles }: {
    theme?: any
    computedStyles?: any
}) => {

    return {
        position: 'absolute',
        fontWeight: 500,
        fontSize: 12,
        left: 14,
        letterSpacing: '-0.45px',
        marginBottom: 3,

        ...computedStyles
    }
})

type InputGroupProps = {
    intent?: IntentType,
    label?: React.ReactChild,
    children: React.ReactChild,
    bottomHint?: React.ReactChild,
    disabled?: boolean,
    style?: React.CSSProperties
}

function getIntentProps({ intent, theme }: { intent: IntentType, theme: Theme }) {
    return { color: theme.fns.intentToColor(intent) }
}

function InputGroup({
    intent = 'none',
    bottomHint,
    children,
    style,
    disabled = false
}: InputGroupProps) {

    const theme = useTheme()


    return (
        <Frame
            disabled={disabled}
            style={style}
        >
            <StyledInputWrapper>
                {children}
            </StyledInputWrapper>
            <BottomHint computedStyles={getIntentProps({ intent, theme })}>
                {bottomHint}
            </BottomHint>
        </Frame>
    )
}

const InputRow = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        marginBottom: 30,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        [theme!.mq.small]: {
            marginBottom: 24,
            alignItems: 'center',
            flexDirection: 'row',
            '> button': {
                marginBottom: 0
            },
        }
    }
})

const InputTitle = styled.p(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '135%',
        flexGrow: 1,
        maxWidth: 200,
        color: theme?.colors.color5,
        marginBottom: 8,

        [theme!.mq.small]: {
            marginBottom: 0
        }
    }
})

export {
    InputGroup,
    BottomHint,
    InputRow,
    InputTitle
}