import React from 'react'
import { toast } from 'react-toastify'
import { QueryClient, QueryCache } from 'react-query'

import {
    ServerErrorToasterMessage,
    ServerForbiddenToasterMessage,
    defaultToasterOptions
} from 'components/toaster-messages'
import { is403Error } from 'utils'

const getServerMessage = e => {
    try {
        if (e.response.data === '') {
            throw new Error(e)
        }

        return <ServerErrorToasterMessage error={e.response.data} />
    }
    catch (e) {
        return (
            e?.response?.data?.error?.errorMessage ||
            e?.response?.data?.error?.errorCode ||
            "Внутренняя ошибка"
        )
    }
}

// toast width - https://github.com/fkhadra/react-toastify/issues/189
function commonErrorHandler(e) {
    if (is403Error(e)) {
        return
    }
    else {
        toast.error(getServerMessage(e), defaultToasterOptions)
    }
}

const defaultOptions = {
    queries: {
        useErrorBoundary: false,
        refetchOnWindowFocus: false,
        retry(failureCount, error) {
            if (error.status === 404) return false
            else if (failureCount < 2) return true
            else return false
        },
        onSuccess: e => {

        },
        onError: commonErrorHandler
    },
    mutations: {
        onError: commonErrorHandler
    }
}

const queryCache = new QueryCache()
const queryClient = new QueryClient({
    queryCache,
    defaultOptions,
})

export {
    queryClient,
    commonErrorHandler
}