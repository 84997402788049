import React from 'react'
import { toast } from 'react-toastify'

import { Success } from './dependencies/success'
import { Error } from './dependencies/error'
import { Warning } from './dependencies/warning'

type NotifyTypes = 'error' | 'success' | 'warning'

export { Error as ServerErrorToasterMessage } from './dependencies/server-error'
export { ServerForbidden as ServerForbiddenToasterMessage } from './dependencies/server-forbidden'

export { Error as ErrorToasterMessage } from './dependencies/error'
export { Success as SuccessToasterMessage } from './dependencies/success'
export { Warning as WarningToasterMessage } from './dependencies/warning'

export const defaultToasterOptions = {
    autoClose: 5000,
    style: {
        backgroundColor: 'white'
    }
}

export function notify({ type, title, message }: {
    type: NotifyTypes,
    title: React.ReactNode
    message?: React.ReactNode
}) {
    switch (type) {
        case 'success':
            toast.success(
                <Success
                    title={title}
                    message={message}
                />,
                defaultToasterOptions
            )
            break;
        case 'error':
            toast.error(
                <Error
                    title={title}
                    message={message}
                />,
                defaultToasterOptions
            )
            break;
        case 'warning':
            toast.warning(
                <Warning
                    title={title}
                    message={message}
                />,
                defaultToasterOptions
            )
            break;
    }
}