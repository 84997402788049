import * as yup from 'yup'

const validateUrlSchema = (value: string) => {
    const ex = yup.string().url().required()
    const yupResult = ex.isValidSync(value)
    return yupResult === true ? yupResult : value.includes('localhost')
}
const emailSchema = yup.string().email().required()
const stringSchema = yup.string().min(1).required()

function castToBoolean(value: string | undefined) {
    return value === 'true'
}

function castToNumber(value: string): number {
    const possibleNumber = Number(value)
    return typeof possibleNumber === 'number' ? possibleNumber : 0
}

class Config {
    static options = {
        DISABLE_NEW_JSX_TRANSFORM: (window as any)._env_.DISABLE_NEW_JSX_TRANSFORM,
        REACT_APP_NAME: (window as any)._env_.REACT_APP_NAME,
        REACT_APP_BRAND_NAME: (window as any)._env_.REACT_APP_BRAND_NAME,
        REACT_APP_API_URL: (window as any)._env_.REACT_APP_API_URL,
        REACT_APP_AUTH_URL: (window as any)._env_.REACT_APP_AUTH_URL,
        REACT_APP_IN_DEV: castToBoolean((window as any)._env_.REACT_APP_IN_DEV),
        REACT_APP_DEFAULT_EMAIL: (window as any)._env_.REACT_APP_DEFAULT_EMAIL,
        REACT_APP_DEFAULT_PASSWORD: (window as any)._env_.REACT_APP_DEFAULT_PASSWORD,
        REACT_APP_DEFAULT_GUEST_MODE_ENABLED: castToBoolean((window as any)._env_.REACT_APP_DEFAULT_GUEST_MODE_ENABLED),
        REACT_APP_24_INTERVAL_RANGE: castToNumber((window as any)._env_.REACT_APP_24_INTERVAL_RANGE),
        MAP_ENVIRONMENT: (window as any)._env_.MAP_ENVIRONMENT
    }

    static updateEndpoints({ authUrl, apiUrl }: { authUrl: string, apiUrl: string }) {
        localStorage.setItem('updatedEndpoints', JSON.stringify({ authUrl, apiUrl }))
    }

    static resetEndpoints() {
        localStorage.removeItem('updatedEndpoints')
    }

    static chooseUrl(urlType: 'auth' | 'api'): string {
        const localStorageEndpoints = localStorage.getItem('updatedEndpoints')
        const urls = localStorageEndpoints ? JSON.parse(localStorageEndpoints) : null

        switch (urlType) {
            case 'api': {
                return urls?.authUrl ? urls.apiUrl : Config.options.REACT_APP_API_URL
            }
            case 'auth': {
                return urls?.authUrl ? urls.authUrl : Config.options.REACT_APP_AUTH_URL
            }
        }
    }

    static isMainUrlOverride(): boolean {
        return typeof localStorage.getItem('updatedEndpoints') === 'string'
    }

    static isFeatureEnabled(feature: 'guest-mode') {
        if (feature === 'guest-mode') {
            return Config.options.REACT_APP_DEFAULT_GUEST_MODE_ENABLED === true
        }

        return false
    }

    static performHealthCheck() {
        let errors: { value: string, message: string }[] = []

        if (!validateUrlSchema(Config.options.REACT_APP_API_URL)) {
            errors.push({ value: 'REACT_APP_API_URL', message: 'Невалидный URL' })
        }

        if (!validateUrlSchema(Config.options.REACT_APP_AUTH_URL)) {
            errors.push({ value: 'REACT_APP_AUTH_URL', message: 'Невалидный URL' })
        }

        if (
            Config.options.REACT_APP_DEFAULT_GUEST_MODE_ENABLED &&
            (
                !emailSchema.isValidSync(Config.options.REACT_APP_DEFAULT_EMAIL) ||
                !stringSchema.isValidSync(Config.options.REACT_APP_DEFAULT_PASSWORD)
            )
        ) {
            errors.push({ value: 'REACT_APP_DEFAULT_GUEST_MODE_ENABLED', message: 'REACT_APP_DEFAULT_EMAIL и REACT_APP_DEFAULT_PASSWORD должны быть заполнены' })
        }

        return errors
    }
}

export {
    Config
}