import { createIntl, createIntlCache } from 'react-intl'
import ru from './languages/ru.json'
import en from './languages/en.json'

import {
    ru as ruMomentLocale,
    enUS as enUSMomentLocale
} from 'date-fns/esm/locale'

const LOCALE = {
    ruRu: 'ru-RU',
    enUS: 'en-US'
}

// --------------------------------------- PRIVATE --------------------------------------- //

// const cache = createIntlCache()

// function chooseLocale(locale) {
//     return Object.values(LOCALE).includes(locale) ? locale : LOCALE.enUS
// }

// function createAppIntl(locale) {
//     const _locale = chooseLocale(locale)
//     return createIntl({
//         locale: _locale,
//         messages: chooseMessages(_locale)
//     }, cache)
// }

// --------------------------------------- PUBLIC --------------------------------------- //

function getDateFnsLocaleObject(currentLocale) {
    switch (currentLocale) {
        case LOCALE.ruRu: return ruMomentLocale
        case LOCALE.enUS: return enUSMomentLocale
        default: return enUSMomentLocale
    }
}

function chooseMessages(currentLocale) {
    switch (currentLocale) {
        case LOCALE.ruRu: return ru
        case LOCALE.enUS: return en
        default: return en
    }
}

function datesShortcutIdToTKey(id, isShort) {
    const calculateKey = (key, isShort) => isShort ? key + '.short' : key
    switch (id) {
        case 'week': return calculateKey('dates.week', isShort)
        case 'three-day': return calculateKey('dates.72h', isShort)
        case 'day': return calculateKey('dates.24h', isShort)
        case '8h': return calculateKey('dates.8h', isShort)
        default: return id
    }
}

function getCalendarTWords({ messages }) {
    return {
        dayNamesUltraShort: messages['calendar.dayNamesUltraShort'],

        dayNamesShort: messages['calendar.dayNamesShort'],
        dayNames: messages['calendar.dayNames'],
        monthNamesShort: messages['calendar.monthNamesShort'],
        monthNames: messages['calendar.monthNames'],
        amPm: messages['calendar.amPm'],
        firstDayOfWeek: messages['calendar.firstDayOfWeek'],
    }
}

export {
    datesShortcutIdToTKey,
    getCalendarTWords,

    LOCALE,
    chooseMessages,
    getDateFnsLocaleObject
}