/** @jsx jsx */
import { jsx, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'

type WarningProps = {
    message?: React.ReactNode,
    title: React.ReactNode
}

const Frame = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontSize: '12px',
        padding: '12px 14px'
    }
})

const Body = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    }
})

const Header = styled.h3(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        margin: 0
    }
})

function Warning({
    message,
    title
}: WarningProps) {

    return (
        <Frame>
            <Header><WarningIcon style={{ marginRight: 8 }} />{title}</Header>
            {
                message && <Body>
                    {message}
                </Body>
            }
        </Frame>
    )
}

export {
    Warning
}