import React from 'react'
import Highcharts from 'highcharts'

import { getCalendarTWords } from 'localization/helpers'

function useUpdateHighchartsGlobally({ messages }) {
    React.useEffect(() => {
        const timeIntl = getCalendarTWords({ messages })
        Highcharts.setOptions({
            lang: {
                months: timeIntl.monthNames,
                shortMonths: timeIntl.monthNamesShort,

                weekdays: timeIntl.dayNames,
                shortWeekdays: timeIntl.dayNamesShort,
            }
        })
    }, [messages])
}

export {
    useUpdateHighchartsGlobally
}