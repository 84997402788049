/** @jsx jsx */
import { jsx, Theme } from '@emotion/react'
import styled from '@emotion/styled'

const Frame = styled.div(({ theme }: { theme?: any }) => ({
    backgroundColor: theme.colors.color22,
    height: '100vh',
    width: '100vw',
}))

const Body = styled.div(({ theme }: { theme?: any }) => ({
    padding: '0px 52px 0px 52px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}))

const Title = styled.div(({ theme }: { theme?: Theme }) => ({
    paddingTop: 52,
    fontSize: 30,
    marginBottom: 36,
    fontWeight: 'bold',
    lineHeight: '34px',
    textAlign: 'left',
    color: theme?.colors.color23
}))

const SubTitle = styled.div(({ theme }: { theme?: Theme }) => ({
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 500,
    letterSpacing: '-0.95px',
    textAlign: 'left',
    marginBottom: 62,
    color: theme?.colors.color23
}))

const CommonError = styled.div(({ theme }) => ({
    margin: '4px 0px 12px 0px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.45px',
    color: theme.colors.color26
}))

const BackToLogin = styled.div(() => ({
    textAlign: 'center',
    marginTop: 26
}))

const CopyrightWrapper = styled.div(() => ({
    marginTop: 'auto',
    marginBottom: 64,
    textAlign: 'center'
}))

const FormValueBlock = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        marginBottom: 24
    }
})

export {
    Frame,
    Body,
    Title,
    SubTitle,
    CommonError,
    BackToLogin,
    CopyrightWrapper,
    FormValueBlock
}