import React from 'react'

import { UseSignalRResult } from './types'

const SignalRConnectionContext = React.createContext<UseSignalRResult>(null!)
function SignalRConnectionProvider({
    connection,
    children
}: {
    connection: UseSignalRResult,
    children: React.ReactNode
}) {
    return (
        <SignalRConnectionContext.Provider value={connection}>
            {children}
        </SignalRConnectionContext.Provider>
    )
}

function useSignalRConnection() {
    const context = React.useContext(SignalRConnectionContext)
    if (context === undefined) {
        throw new Error(`SignalRConnectionContext must be used within a SignalRConnectionProvider`)
    }
    return context
}

function SignalRConnectionStatus({ status }: { status: any }) {
    if (status === "Disconnected") {
        return <span>Disconnected</span>
    }

    if (status === "Connecting") {
        return <span>Connecting</span>
    }

    if (status === "Connected") {
        return <span>Connected</span>
    }

    if (status === "Disconnecting") {
        return <span>Disconnecting</span>
    }

    if (status === "Reconnecting") {
        return <span>Reconnecting</span>
    }

    return "123"
}

export {
    SignalRConnectionProvider,
    useSignalRConnection,
    SignalRConnectionStatus
}