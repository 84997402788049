/** @jsx jsx */
import { jsx, Theme, Interpolation } from '@emotion/react'
import React from 'react'
import { useTheme } from '@emotion/react'
import { Link } from 'react-router-dom'

import { SizeType, UnderlineType } from 'types'

type variantProps = 'primary' | 'secondary'

type NavLinkProps = {
    text: React.ReactNode,
    size?: SizeType,
    variant?: variantProps,
    underline?: UnderlineType,
    onClick?: () => void,
    to: string
}

function mainStyles({ theme, variant, underline, computedStyles }: {
    theme: Theme,
    variant?: variantProps,
    underline: UnderlineType,
    computedStyles: any
}): Interpolation<Theme> {

    return {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '22px',
        letterSpacing: '-0.45px',
        color: theme?.colors.color16,
        WebkitTapHighlightColor: 'transparent',
        textDecoration: underline === 'always' ? 'underline' : 'none',

        ':hover': {
            filter: 'brightness(120%)',
            transition: 'all .2s',
            textDecoration: underline === 'none' ? 'none' : 'underline',
        },

        ':active': {
            transform: 'translateY(1px)',
            transition: 'all .2s',
            textDecoration: underline === 'none' ? 'none' : 'underline',
        },

        ...computedStyles
    }
}

function getSizeProps({ size }: { size: SizeType }) {
    switch (size) {
        case 'small':
            return {
                fontWeight: 'normal',
                fontSize: 16,
                lineHeight: '20px',
            }
        case 'medium':
            return {
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '22px',
            }
        case 'large':
            return {
                fontWeight: 'bold',
                fontSize: 20,
                lineHeight: '24px',
            }
    }
}

function NavLink({
    variant,
    text,
    size = 'medium',
    underline = 'always',
    to,
    onClick,
    ...otherProps
}: NavLinkProps) {

    const computedStyles = getSizeProps({ size })
    const theme = useTheme()
    const linkStyles = mainStyles({ theme, variant, underline, computedStyles })

    return (
        <Link
            to={to}
            onClick={onClick}
            css={[
                linkStyles
            ]}
            {...otherProps}
        >
            {text}
        </Link>
    )
}

export {
    NavLink
}

        // <StyledComponent
        //     {...otherProps}
        //     variant={variant}
        //     underline={underline}
        //     href='#'
        //     onClick={e => { // https://github.com/facebook/react/issues/16382
        //         e.preventDefault()
        //         onClick?.(e)
        //         return false
        //     }}
        //     computedStyles={getSizeProps({ size })}
        // >
        //     {text}
        // </StyledComponent>