import { Config } from 'utils/config'

function refreshToken() {
    return _client('token/refresh', null, null, 'GET')
}

function login({ email, password }: { email: string, password: string }) {
    return _client('token/login', null, { email, password }, 'POST')
}

async function logout() {
    try {
        const response = await refreshToken()
        return _client('token/revoke', response.access.token, null, 'POST')
    } catch (e) {
        return Promise.resolve({ status: 200 })
    }
}

function register({ username, password }: { username: string, password: string }) {
    return null
}

function recoveryPassword({ email }: { email: string }) {
    return _client('users/password/recovery/email', null, { email }, 'POST')
}

function resetPassword({ email, newPassword, recoveryCode }: { email: string | string[] | null, newPassword: string, recoveryCode: string }) {
    return _client('users/password/reset', null, { email, newPassword, recoveryCode }, 'PUT')
}
function changePassword({ id, newPassword, oldPassword, token }: { id: string, newPassword: string, oldPassword: string, token: string }) {
    return _client(`users/${id}/password`, token, { newPassword, oldPassword }, 'PUT')
}

async function _client(endpoint: string, token: string | null, data: any, method: string) {
    let config: any = {
        method,
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    if (data) {
        config.body = JSON.stringify(data)
    }

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return window.fetch(`${Config.chooseUrl('auth')}/${endpoint}`, config).then(async response => {
        if (response.ok) {
            try {
                return await response.json()
            } catch (e) {
                return response
            }
        } else {
            return Promise.reject(response)
        }
    })
}


export {
    refreshToken,

    login,
    logout,

    recoveryPassword,
    resetPassword,
    changePassword
}
