/** @jsx jsx */
import { jsx, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { ReactComponent as StopIcon } from 'assets/icons/stop.svg'

type ConfigWarningProps = {
    messages: { value: string, message: string }[]
}

const Frame = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20vh'
    }
})

const Title = styled.h3(({ theme }: {
    theme?: Theme
}) => {

    return {
        justifyContent: 'left',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 48
    }
})

const Body = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        flexDirection: 'column'
    }
})

const Row = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        borderTop: '1px solid #7F7F7F',
        padding: '24px 8px',
        flexDirection: 'column',


        '@media (min-width: 720px)': {
            flexDirection: 'row'
        }
    }
})

const Value = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontSize: 12,
        fontWeight: 'bold',

        '@media (min-width: 720px)': {
            width: 300,
        }
    }
})

const Message = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontSize: 12,

        '@media (min-width: 720px)': {
            width: 400,
        }
    }
})

function ConfigWarning({
    messages
}: ConfigWarningProps) {

    return (
        <Frame>
            <Title>
                <StopIcon style={{ marginRight: 8 }} />
                Предупреждение
            </Title>
            <Body>
                {
                    messages.map(x => (
                        <Row>
                            <Value>{x.value}</Value>
                            <Message>{x.message}</Message>
                        </Row>
                    ))
                }
            </Body>
        </Frame>
    )
}

export {
    ConfigWarning
}