/** @jsx jsx */
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

const Frame = styled.div(() => ({
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    height: '100vh',
    width: '100vw',

    display: 'flex',
    flexDirection: 'column',
}))

const Div100Percent = styled.div(() => ({
    height: '100%',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

const CardWrapper = styled.div(() => ({
    width: 532,
}))

const Title = styled.div(({ theme }: { theme?: Theme }) => ({
    paddingTop: 52,
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: '34px',
    textAlign: 'center',
    marginBottom: 20,
    color: theme?.colors.color23
}))

const SubTitle = styled.div(({ theme }: { theme?: Theme }) => ({
    margin: '0 100px',
    marginBottom: 46,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '-0.95px',
    textAlign: 'center',
    color: theme?.colors.color1
}))

const StyledForm = styled.div(() => ({
    padding: '0px 120px',
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

}))

const CopyrightWrapper = styled.div(() => ({
    height: 72,
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

const CommonError = styled.div(({ theme }: { theme?: Theme }) => ({
    margin: '4px 0px 8px 0px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.45px',
    color: theme?.colors.color26
}))

const FormValueBlock = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        marginBottom: 24
    }
})

export {
    Frame,
    Div100Percent,
    CardWrapper,
    Title,
    SubTitle,
    StyledForm,
    CopyrightWrapper,
    CommonError,
    FormValueBlock
}