import React from 'react'
import * as auth from 'auth-provider'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import FullPageSpinner from 'components/full-page-spinner'
import { queryClient } from 'utils/react-query-config'
import { useQuery, useMutation } from 'react-query'
import { MainGateway } from 'api/main-gateway'
import { notConcurrent } from 'utils'
import { Config } from 'utils/config'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const refreshTokenSynced = notConcurrent(auth.refreshToken)

const HOOK_STATUS = {
    IDLE: 'IDLE', // начальный стейт

    INITIAL_TRY_FAILED: 'INITIAL_TRY_FAILED', // не удалось получить токен или профиль через cookie
    INITIAL_TRY_SUCCESS: 'INITIAL_TRY_SUCCESS', // удалось получить токен и профиль через cookie

    LOGIN_TRY_FAILED: 'LOGIN_TRY_FAILED', // не удалось получить логин через пароль
    LOGIN_TRY_SUCCESS: 'LOGIN_TRY_SUCCESS', // удалось получить токен и профиль через пароль

    LOGOUT_TRY_SUCCESS: 'LOGOUT_TRY_SUCCESS', // удалось успешно выйти
    LOGOUT_TRY_FAILED: 'LOGOUT_TRY_FAILED', // не удалось выйти
}

function getInitialAuthInfo() {
    return {
        info: {
            hookStatus: HOOK_STATUS.IDLE,
            originError: null,
            httpCode: -1,
        },
        user: null,
        token: null,
        roles: []
    }
}

function AuthProvider(props) {

    const location = useLocation()
    const {
        preventAutoLogin = 0
    } = queryString.parse(location.search)

    const [authInfo, setAuthInfo] = React.useState(getInitialAuthInfo())

    const loginQuery = useMutation(x => {
        return auth.login(x)
    }, {
        onSuccess({
            access: { token },
            user
        }) {
            const info = {
                hookStatus: HOOK_STATUS.LOGIN_TRY_SUCCESS,
                originError: null,
                httpCode: 200,
            }

            setAuthInfo(x => ({ ...x, token, user, info }))
        },
        onError(e) {
            const info = {
                hookStatus: HOOK_STATUS.LOGIN_TRY_FAILED,
                originError: e,
                httpCode: e?.status || -1,
            }

            setAuthInfo(x => ({ ...x, info }))
        }
    })

    const logoutQuery = useMutation(x => {
        return auth.logout()
    }, {
        onSuccess() {
            queryClient.clear()
            const logoutState = getInitialAuthInfo()
            logoutState.info.hookStatus = HOOK_STATUS.LOGOUT_TRY_SUCCESS
            setAuthInfo(logoutState)
        },
        onError(error) {
            const info = {
                hookStatus: HOOK_STATUS.LOGOUT_TRY_FAILED,
                originError: error,
                httpCode: error?.status || -1,
            }

            setAuthInfo(x => ({ ...x, info }))
        }
    })

    const updateToken = React.useCallback(async () => {
        try {
            const response = await refreshTokenSynced()
            setAuthInfo(x => ({ ...x, token: response.access.token }))
            return response
        } catch (e) {
            setAuthInfo(x => (getInitialAuthInfo()))
            return null
        }
    }, [])

    const initialQuery = useQuery(['initialAuthQuery'], async () => {
        return auth.refreshToken()
    }, {
        retry: 0,
        enabled: authInfo.info.hookStatus !== HOOK_STATUS.LOGIN_TRY_SUCCESS
            && authInfo.info.hookStatus !== HOOK_STATUS.LOGOUT_TRY_SUCCESS
            && authInfo.info.hookStatus !== HOOK_STATUS.LOGIN_TRY_FAILED
            && (authInfo.info.hookStatus === HOOK_STATUS.IDLE &&
                ((Config.isFeatureEnabled('guest-mode') && parseInt(preventAutoLogin) === 0) || !Config.isFeatureEnabled('guest-mode'))
            ),
        onSuccess({
            access: { token },
            user
        }) {
            const info = {
                hookStatus: HOOK_STATUS.INITIAL_TRY_SUCCESS,
                originError: null,
                httpCode: 200,
            }

            setAuthInfo(x => ({ ...x, token: token, user, info }))
        },
        onError(error) {
            if (Config.isFeatureEnabled('guest-mode')) {
                loginAsGuest()
                return
            }

            const info = {
                hookStatus: HOOK_STATUS.INITIAL_TRY_FAILED,
                originError: error,
                httpCode: error?.status || -1,
            }

            setAuthInfo(x => ({ ...x, info }))
        }
    })

    function loginAsGuest() {
        loginQuery.mutate({
            email: Config.options.REACT_APP_DEFAULT_EMAIL,
            password: Config.options.REACT_APP_DEFAULT_PASSWORD
        })
    }

    if (initialQuery.status === 'loading') {
        return <FullPageSpinner />
    }

    return (
        <AuthContext.Provider
            value={{
                ...authInfo,
                updateToken,
                login: loginQuery.mutateAsync,
                loginIsLoading: loginQuery.isLoading,

                logout: logoutQuery.mutateAsync,
                logoutIsLoading: logoutQuery.isLoading,
                loginAsGuest: Config.isFeatureEnabled('guest-mode') ? loginAsGuest : null
            }}
            {...props}
        />
    )
}

function useAuth() {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`)
    }
    return context
}

function useClient() {
    const { token, updateToken } = useAuth()
    return React.useMemo(
        () => {
            return new MainGateway({
                baseUrl: Config.chooseUrl('api'),
                token,
                updateToken
            })
        },
        [token, updateToken]
    )
}

export { AuthProvider, useAuth, useClient }
