import React from 'react'
import * as signalR from '@microsoft/signalr'
import debounce from 'debounce-promise'

import { UseSignalRResult } from './types'
// export declare enum HubConnectionState {
//     /** The hub connection is disconnected. */
//     Disconnected = "Disconnected",
//     /** The hub connection is connecting. */
//     Connecting = "Connecting",
//     /** The hub connection is connected. */
//     Connected = "Connected",
//     /** The hub connection is disconnecting. */
//     Disconnecting = "Disconnecting",
//     /** The hub connection is reconnecting. */
//     Reconnecting = "Reconnecting"
// }

//https://docs.microsoft.com/ru-ru/javascript/api/@aspnet/signalr/hubconnection?view=signalr-js-latest

type UseSignalRProps = {
    url: string
    disabled?: boolean
    reconnectionTimeoutMs?: number
}


function initSignalR({ url }: { url: string }) {
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .build()

    connection.serverTimeoutInMilliseconds = 1000 * 60 * 60 * 4 // 1000ms = 1s
    return connection
}

function useSignalR({ url, disabled = false, reconnectionTimeoutMs = 2000 }: UseSignalRProps): UseSignalRResult {
    const connection = React.useRef(initSignalR({ url }))
    const [status, setStatus] = React.useState(connection.current.state)
    const [reconnectToken, setReconnectToken] = React.useState(0)
    const setReconnectTokenDebounced = React.useRef(debounce(setReconnectToken, reconnectionTimeoutMs))

    React.useEffect(() => {
        if (disabled) {
            return
        }

        connection.current.start()
            .then(() => setStatus(connection.current.state))
            .catch(err => {
                setStatus(connection.current.state)
                setReconnectTokenDebounced.current(prev => prev + 1)
            })

        connection.current.onreconnecting(e => {
            setStatus(connection.current.state)
        })

        connection.current.onreconnected(e => {
            setStatus(connection.current.state)
        })

        connection.current.onclose(e => {
            setStatus(connection.current.state)
            setReconnectTokenDebounced.current(prev => prev + 1)
        })

        return () => {
            connection.current.stop()
        }

    }, [reconnectToken])

    return { connection: connection.current, status }
}

export {
    useSignalR
}