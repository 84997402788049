/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'

import React from 'react'

const ComponentWrapper = styled.div(({
    theme,
    borderRadius,
    interactive = false
}: {
    theme?: any,
    borderRadius: number,
    interactive?: boolean
}) => ({
    background: theme.colors.color22,
    borderRadius: borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.color14,
    boxShadow: `4px 0px 14px rgba(0, 0, 0, 0.04)`,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    cursor: interactive ? 'pointer' : 'unset',
    transition: 'all .2s',
    ':active': {
        boxShadow: interactive ? `4px 0px 14px rgba(0, 0, 0, 0.12) !important` : 'inherit'
    },
    ':hover': {
        boxShadow: interactive ? `4px 0px 14px rgba(0, 0, 0, 0.08)` : 'inherit'
    }
}))

type CardProps = {
    children: React.ReactNode,
    borderRadius?: number,
    style?: React.CSSProperties,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    interactive?: boolean
}


function Card({
    children,
    borderRadius = 8,
    onClick,
    interactive,
    style,
}: CardProps) {
    return (
        <ComponentWrapper
            onClick={onClick}
            borderRadius={borderRadius}
            style={style}
            interactive={interactive}
        >
            {children}
        </ComponentWrapper>
    )
}

export {
    Card
}