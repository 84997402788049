import { arrayToQueryParams } from 'utils'

type GetSensorDeviceBindings_Request = {
    states: {
        online?: boolean // онлайн
        offline?: boolean // оффлайн
        unavailable?: boolean // нет регистрации,
    }
    publicState: {
        public?: boolean
        private?: boolean
    }
    compositionTypes: {
        placementWithBaseStation?: boolean //Пост со станцией, 
        baseStationWithoutPlacement?: boolean  // станция без поста,
        placementWithoutBaseStation?: boolean // пост без станции 
    }
    floorIds?: NumericId[]
}

function requestDataToQps(data: GetSensorDeviceBindings_Request): string {
    let qps = ''

    // floorIds
    if (data.floorIds?.length !== 0) {
        qps += arrayToQueryParams(data.floorIds || [], 'floorIds')
    }

    //compositionTypes
    if (data.compositionTypes.placementWithBaseStation) {
        qps += '&compositionTypes=postWithBaseStation'
    }

    if (data.compositionTypes.baseStationWithoutPlacement) {
        qps += '&compositionTypes=baseStationWithoutPost'
    }

    if (data.compositionTypes.placementWithoutBaseStation) {
        qps += '&compositionTypes=postWithoutBaseStation'
    }

    // states
    if (data.states.online) {
        qps += '&states=online'
    }

    if (data.states.offline) {
        qps += '&states=offline'
    }

    if (data.states.unavailable) {
        qps += '&states=unavailable'
    }

    if (data.publicState.public && data.publicState.private) {

    } else if (data.publicState.public) {
        qps += `&isPublic=true`
    } else if (data.publicState.private) {
        qps += `&isPublic=false`
    }

    return qps
}

export type {
    GetSensorDeviceBindings_Request
}

export {
    requestDataToQps
}