import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import * as auth from 'auth-provider'
import { Config } from 'utils/config'
import { identityErrorDescribeHandler } from 'utils'

type FormInputs = {
    email: string
    common: string
}

const schema = yup.object().shape({
    email: yup.string().email().required(),
})

const getIntent = (message: string | undefined) => {
    const intent: IntentType = message ? 'danger' : 'none'
    return intent
}

function useModule() {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const recoveryQuery = useMutation((x: any) => {
        return auth.recoveryPassword(x)
    }, {
        onSuccess(data, variables) {
            navigate(`/restore/confirm-password?email=${variables.email}`);
        },
        async onError(e) {
            const message = formatMessage({ id: 'app.module.restore.restorePasswordFailed' })
            const responseMessage = await identityErrorDescribeHandler(e)
            formProps.setError("common", { message: responseMessage || message })
        }
    })

    const formProps = useForm<FormInputs>({
        mode: 'onChange',
        resolver: yupResolver(schema)
    })

    const onSubmit = async (data: FormInputs) => {
        await recoveryQuery.mutateAsync({ email: data.email })
    }

    return {
        ...formProps,
        getIntent,
        onSubmit,
        backToLoginUrl: Config.isFeatureEnabled('guest-mode') ? '/?preventAutoLogin=1' : '/'
    }
}

export {
    useModule
}