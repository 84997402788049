import axios from 'axios'

import { handleErrorsInterceptor } from './interceptors/response/handle-errors-interceptor'

import { addLanguageInterceptor } from './interceptors/request/add-language-interceptor'

const client = ({
    baseUrl,
    token,
    updateToken
}) => {
    const options = {
        ...{
            baseURL: baseUrl,
            validateStatus: status => true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        },
    }

    const instance = axios.create(options)

    // REQUEST INTERCEPTORS
    instance.interceptors.request.use(addLanguageInterceptor)

    // RESPONSE INTERCEPTORS
    instance.interceptors.response.use(
        response => handleErrorsInterceptor(response, updateToken, instance)
    )

    return instance
}

export default client
