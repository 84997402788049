import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { notify } from 'components/toaster-messages'
import * as auth from 'auth-provider'
import { Config } from 'utils/config'
import { identityErrorDescribeHandler } from 'utils'

type FormInputs = {
    recoveryCode: string,
    newPassword: string
    newPasswordConfirm: string
    common: string
}

const schema = yup.object().shape({
    recoveryCode: yup.string().required(),
    newPassword: yup.string().required(),
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
})

const getIntent = (message: string | undefined) => {
    const intent: IntentType = message ? 'danger' : 'none'
    return intent
}

function useModule() {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { email } = queryString.parse(window.location.search)

    const formProps = useForm<FormInputs>({
        mode: 'onChange',
        resolver: yupResolver(schema)
    })

    const resetQuery = useMutation((data: FormInputs) => {
        return auth.resetPassword({
            email: email,
            recoveryCode: data.recoveryCode,
            newPassword: data.newPassword,
        })
    }, {
        onSuccess(data, variables) {

            notify({
                type: 'success',
                title: 'Ваш пароль успешно изменён'
            })

            navigate(`/?preventAutoLogin=1`)
        },
        async onError(e) {
            const message = formatMessage({ id: 'app.module.restore.restorePasswordFailed' })
            const responseMessage = await identityErrorDescribeHandler(e)
            formProps.setError("common", { message: responseMessage || message })
        }
    })


    const onSubmit = async (data: FormInputs) => {
        await resetQuery.mutateAsync(data)
    }

    return {
        ...formProps,
        getIntent,
        onSubmit,
        backToLoginUrl: Config.isFeatureEnabled('guest-mode') ? '/?preventAutoLogin=1' : '/'
    }
}

export {
    useModule
}