/** @jsx jsx */
import { jsx, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'

const Frame = styled.div(({ theme }: {
    theme?: Theme
}) => {
    return {
        fontSize: '12px',
        padding: '12px 14px'
    }
})

const Header = styled.h3(({ theme }: {
    theme?: Theme
}) => {
    return {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        margin: 0
    }
})

function ServerForbidden() {
    return (
        <Frame>
            <Header>
                <div style={{ marginRight: 8, width: 15, height: 15 }} >
                    <WarningIcon />
                </div>
                <span>
                    У вас нет доступа для выполнения данной операции
                </span>
            </Header>

        </Frame >
    )
}

export {
    ServerForbidden
}