import { AxiosInstance, AxiosResponse } from 'axios'
import { stringify } from 'query-string'

import client from './client'

import { arrayToQueryParams } from 'utils'

import { GetBuildings_ServerJson } from './server-json-types/get-buildings'
import { GetOutdoorClimate_ServerJson } from './server-json-types/get-outdoor-climate'
import { GetPlacementsId_ServerJson } from './server-json-types/get-placements-id'
import { GetUserId_ServerJson } from './server-json-types/get-user-id'
import { PostPlacementId_ServerJson } from './server-json-types/post-placement-id'
import { GetDeviceId_ServerJSON, GetDevice_ServerRequest } from './server-json-types/get-device-id'
import { PostDeviceId_ServerJson } from './server-json-types/post-device-id'
import { GetBuildingsId_ServerResponse } from './server-json-types/get-buildings-id'
import { DevicesPlacements_Put_ServerRequest } from './server-json-types/put-devicesPlacements-id'
import { GetCities_ServerJSON } from './server-json-types/get-cities'
import { GetCitiesId_ServerJSON } from './server-json-types/get-cities-id'
import { PostCityId_ServerRequest } from './server-json-types/post-city-id'
import { PostBuildingId_ServerRequest } from './server-json-types/post-building-id'
import { PostFloorsId_ServerRequest } from './server-json-types/post-floors-id'
import { GetFloorsId_ServerResponse } from './server-json-types/get-floors id'
import { GetRoomsId_ServerResponse } from './server-json-types/get-rooms-id'
import { PostRoomsId_ServerRequest } from './server-json-types/post-rooms-id'
import { GetDeviceInfo_ServerJSON } from './server-json-types/get-device-info'
import { GetUniversalDevices_ServerJSON } from './server-json-types/get-universal-devices'
import { GetAttachment_ServerResponse } from './server-json-types/get-attachment'
import { PostCommand_ServerRequest } from './server-json-types/post-command'
import { PostStartCo2AutoMode_ServerRequest } from './server-json-types/post-startco2-automode'
import { PostStartPmAutoMode_ServerRequest } from './server-json-types/post-startpm-automode'
import { GetLastVaLues_ServerResponse } from './server-json-types/get-last-values'
import { GetPlacementExport_ServerRequest } from './server-json-types/get-placement-export'
import { GetSensorDeviceBindings_Request, requestDataToQps } from './server-json-types/get-sensor-device-bindings'
import { NotificationSettings_ServerJSON } from './server-json-types/get-notification-settings'
import { GetBindingsFilters } from './server-json-types/get-bindings-filters'
import { PostUser_ServerRequest } from './server-json-types/post-user'
import { GetPermissions_ServerJson } from './server-json-types/get-permissions'
import { PutPermissions_ServerJson } from './server-json-types/put-permissions'
import { UniversalDeviceView } from './server-json-types/universal-device-view'
import { GetAvailableSensors } from './server-json-types/get-available-sensors'

//TODO проверить все названия запросов и ответов
// для каждого метода свой
// ------------------------------
//Placement_Post_ServerRequest          - ушло на сервер
//Placement_Post_ServerResponse         - пришло с сервера

function createFormData(formFields: any): FormData {
    let formData = new FormData()
    for (const [key, value] of Object.entries(formFields)) {
        if (value instanceof Blob) {
            formData.append(key, value)
        }

        else if (typeof value === 'string' || typeof value === 'number') {
            formData.append(key, value.toString())
        }

        else if (value === null) {
            continue
        }

        else {
            throw new Error(`Unknown type of value: ${value}`)
        }
    }

    return formData
}

function addFormRequestHeaders() {
    return {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryzuW5nPZQFQCwQtg4'
        },
    }
}

function toQpDatesInterval(from: any, to: any) {
    return (from && to) ? `&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}` : ''
}

type MainGatewayProps = {
    baseUrl: 'string',
    token: 'string',
    updateToken: () => any
}

class MainGateway {

    client: AxiosInstance

    constructor({
        baseUrl,
        token,
        updateToken
    }: MainGatewayProps) {
        this.client = client({ baseUrl, token, updateToken })
    }


    // ------ USERS CONTROLLER
    getUsers(filters: any): Promise<AxiosResponse<GetUserId_ServerJson[]>> {
        return this.client.get(`/users`)
    }

    getPermissions(id: NullOrUndefined<string>): Promise<AxiosResponse<GetPermissions_ServerJson>> {
        return this.client.get(`/users/${id}/permissions`)
    }

    getUser(id: string): Promise<AxiosResponse<GetUserId_ServerJson>> {
        return this.client.get(`/users/${id}`)
    }

    addUser(data: PostUser_ServerRequest): Promise<AxiosResponse<GetUserId_ServerJson>> {
        return this.client.post(`/users`, data)
    }

    updateUser(data: PostUser_ServerRequest): Promise<AxiosResponse<GetUserId_ServerJson>> {
        return this.client.put(`/users/${data.id}`, data)
    }

    updatePermissions(userId: string, data: PutPermissions_ServerJson): Promise<AxiosResponse> {
        return this.client.put(`/users/${userId}/permissions`, data)
    }

    deleteUser(id: string): Promise<AxiosResponse> {
        return this.client.delete(`/users/${id}`)
    }


    // ------ FLOOR_SCHEMAS CONTROLLER
    getMap(id: string | number) {
        return this.client.get(`/floors/schemas/${id}/svg`)
    }

    postMap(id: number, fileList: any) {
        const docs = new FormData()

        docs.append('uploadedFile', fileList[0])

        return this.client.post(`/floors/schemas/${id}/svg`, docs, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }


    // ------ FLOORS CONTROLLER
    getFloor(id: number): Promise<AxiosResponse<GetFloorsId_ServerResponse>> {
        return this.client.get(`/floors/${id}`)
    }

    postFloor(data: PostFloorsId_ServerRequest) {
        return this.client.post(`/floors`, data)
    }

    putFloor(data: PostFloorsId_ServerRequest) {
        return this.client.put(`/floors/${data?.id}`, data)
    }

    deleteFloor(id: number) {
        return this.client.delete(`/floors/${id}`)
    }


    // ------ CITIES CONTROLLER
    getCities(): Promise<AxiosResponse<GetCities_ServerJSON>> {
        return this.client.get(`/cities`)
    }

    getCity(id: number): Promise<AxiosResponse<GetCitiesId_ServerJSON>> {
        return this.client.get(`/cities/${id}`)
    }

    postCity(data: PostCityId_ServerRequest) {
        return this.client.post(`/cities`, data)
    }

    putCity(data: PostCityId_ServerRequest) {
        return this.client.put(`/cities/${data.id}`, data)
    }

    deleteCity(id: number) {
        return this.client.delete(`/cities/${id}`)
    }


    // ------ BUILDINGS CONTROLLER
    getOutdoorClimate({ from, to, deltaSeconds, buildingId }: { from: string, to: string, deltaSeconds: number, buildingId: number }) {
        return this.client.get(`/buildings/${buildingId}/outdoor-climate?${toQpDatesInterval(from, to)}&deltaSeconds=${deltaSeconds}`)
    }

    getOutdoorClimateLive(buildingId: number) {
        return this.client.get(`/buildings/${buildingId}/outdoor-climate/live`)
    }

    getBuildings(): Promise<AxiosResponse<GetBuildings_ServerJson>> {
        return this.client.get(`/buildings`)
    }

    getBuilding(id: number): Promise<AxiosResponse<GetBuildingsId_ServerResponse>> {
        return this.client.get(`/buildings/${id}`)
    }

    postBuilding(data: PostBuildingId_ServerRequest) {
        return this.client.post(`/buildings`, data)
    }

    updateBuilding(data: PostBuildingId_ServerRequest) {
        return this.client.put(`/buildings/${data.id}`, data)
    }

    deleteBuilding(id: number) {
        return this.client.delete(`/buildings/${id}`)
    }


    // ------ TEST CONTROLLER
    authTest() {
        return this.client.get(`/test/user`)
    }

    authTestAdmin() {
        return this.client.get(`/test/admin`)
    }


    // ------ PLACEMENTS CONTROLLER
    getLastValues(placementIds: NumericId[], queryParams: string): Promise<AxiosResponse<GetLastVaLues_ServerResponse>> {
        return this.client.get(`/placements/getLastValues?${arrayToQueryParams(placementIds, 'placementIds')}&${queryParams}`)
    }

    getClimateMeasurements({ floorId, placementId }: { floorId: NullOrUndefined<NumericId>, placementId: NullOrUndefined<NumericId> }) {
        let queryString = 'deltaSeconds=300'
        if (floorId) {
            queryString += `&floorId=${floorId || null}`
        }
        if (placementId) {
            queryString += `&placementId=${placementId || null}`
        }
        return this.client.get(`/placements/climates?${queryString}`)//.then(patchWithMapInfo)//.then(x => addRandData(x))
    }

    getHistoryClimateMeasurements({ from, to, deltaSeconds, floorId, placementId
    }: { from: string, to: string, deltaSeconds: number, floorId: NullOrUndefined<NumericId>, placementId: NullOrUndefined<NumericId> }) {
        let queryString = `${toQpDatesInterval(from, to)}&deltaSeconds=${deltaSeconds}`
        if (floorId) {
            queryString += `&floorId=${floorId || null}`
        }
        if (placementId) {
            queryString += `&placementId=${placementId || null}`
        }
        return this.client.get(`/placements/climates?${queryString}`)//.then(patchWithMapInfo)//.then(x => addFakeDevices(x, 50))
    }

    getPlacements(): Promise<AxiosResponse<GetPlacementsId_ServerJson[]>> {
        return this.client.get(`/placements`)
    }

    getPlacement(id: number): Promise<AxiosResponse<GetPlacementsId_ServerJson>> {
        return this.client.get(`/placements/${id}`)
    }

    putPlacement({ id, data }: { id: number, data: PostPlacementId_ServerJson }): Promise<AxiosResponse<GetPlacementsId_ServerJson>> {
        return this.client.put(`/placements/${id}`, data)
    }

    postPlacement(data: PostPlacementId_ServerJson): Promise<AxiosResponse<GetPlacementsId_ServerJson>> {
        return this.client.post(`/placements`, data)
    }

    deletePlacement(id: number) {
        return this.client.delete(`/placements/${id}`)
    }

    exportPlacementSensorValues({
        ids,
        dateFrom,
        dateTo,
        timeFrom,
        timeTo
    }: GetPlacementExport_ServerRequest) {
        let qp = `&dateFrom=${dateFrom}&dateTo=${dateTo}`
        qp += timeFrom ? `&timeFrom=${timeFrom}` : ''
        qp += timeTo ? `&timeTo=${timeTo}` : ''

        return this.client.get(`/placements/export?${arrayToQueryParams(ids, 'placementIds')}${qp}`, { responseType: 'blob' })
    }

    setLocation({ id, x, y }: { id: string, x: number, y: number }) {
        return this.client.post(`/placements/${id}/location`, { x, y })
    }


    // ------ BINDINGS CONTROLLER
    getSensorDeviceBindings(data: GetSensorDeviceBindings_Request): Promise<AxiosResponse<object[]>> {
        return this.client.get(`/bindings?${requestDataToQps(data)}`)
    }

    getBindingsFilter(): Promise<AxiosResponse<GetBindingsFilters>> {
        return this.client.get(`/bindings/filters`)
    }


    // ------ DEVICES CONTROLLER
    getDevice(id: number): Promise<AxiosResponse<GetDeviceId_ServerJSON>> {
        return this.client.get(`/devices/${id}`)
    }

    getUniversalDevices(): Promise<AxiosResponse<GetUniversalDevices_ServerJSON>> {
        return this.client.get(`/devices?onlyUnplaced=false`)
    }

    postDevice(data: PostDeviceId_ServerJson): Promise<AxiosResponse<GetDeviceId_ServerJSON>> {
        return this.client.post(`/devices`, data)
    }

    putDevice({ id, data }: { id: number, data: PostDeviceId_ServerJson }): Promise<AxiosResponse<GetDeviceId_ServerJSON>> {
        return this.client.put(`/devices/${id}`, data)
    }

    deleteDevice(id: number) {
        return this.client.delete(`/devices/${id}`)
    }

    getDevices(filters: GetDevice_ServerRequest): Promise<AxiosResponse<GetDeviceId_ServerJSON[]>> {
        const filtersQueryStr = stringify(filters)
        return this.client.get(`/devices?${filtersQueryStr}`)
    }


    //------ DEVICES-PLACEMENTS CONTROLLER
    putDevicesPlacements({ id, data }: { id: number, data: DevicesPlacements_Put_ServerRequest }) {
        return this.client.put(`/devices-placements/${id}`, data)
    }
    deleteDevicesPlacements(id: number) {
        return this.client.delete(`/devices-placements/${id}`)
    }


    //------ ROOMS CONTROLLER
    getRoom(id: number): Promise<AxiosResponse<GetRoomsId_ServerResponse>> {
        return this.client.get(`/rooms/${id}`)
    }

    postRoom(data: PostRoomsId_ServerRequest) {
        return this.client.post(`/rooms`, data)
    }

    putRoom(data: PostRoomsId_ServerRequest) {
        return this.client.put(`/rooms/${data.id}`, data)
    }

    deleteRoom(id: number) {
        return this.client.delete(`/rooms/${id}`)
    }



    // ------ SETTINGS CONTROLLER
    uploadFile({ file, name }: { file: File, name: string }) {
        const docs = new FormData()
        docs.append('uploadedFile', file)

        return this.client.post(`/settings/upload-file?fileName=${name}`, docs, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    getAvailableSensors(): Promise<AxiosResponse<GetAvailableSensors>> {
        return this.client.get(`/settings/display/sensors`)
    }

    setAvailableSensors(sensors: Array<{ sensorId: string }>): Promise<AxiosResponse> {
        return this.client.post(`/settings/display/sensors`, { sensors })
    }

    //------ AUTOMODE CONTROLLER
    startCo2Automode(data: PostStartCo2AutoMode_ServerRequest): Promise<AxiosResponse<any>> {
        return this.client.post(`/automode/runco2automode`, data)
    }

    stopCo2Automode(data: string[]) {
        return this.client.post(`/automode/stopco2automode`, { taskIds: data })
    }

    startPmAutomode(data: PostStartPmAutoMode_ServerRequest) {
        return this.client.post(`/automode/runpmautomode`, data)
    }

    stopPmAutomode(data: string[]) {
        return this.client.post(`/automode/stoppmautomode`, { taskIds: data })
    }


    //------ COMMANDS CONTROLLER
    postSendCommand(request: PostCommand_ServerRequest) {
        return this.client.post(`/devicecommands/send`, request)
    }


    //------- DEVICE CONTROLLER
    getDevicesInfo(mac: string): Promise<AxiosResponse<GetDeviceInfo_ServerJSON>> {
        return this.client.get(`/realdevices/${mac}`)
    }

    //------- USER SETTINGS CONTROLLER
    getGlobalSettings(): Promise<AxiosResponse<NotificationSettings_ServerJSON>> {
        return this.client.get(`/settings/getUserSettings?isGlobalSettings=true`)
    }

    saveGlobalSettings(data: NotificationSettings_ServerJSON): Promise<AxiosResponse> {
        return this.client.post(`settings/addOrUpdateGlobalUserSettings`, data)
    }

    //-------- ATTACHMENTS CONTROLLER
    saveAttachment({ file }: { file: File }): Promise<AxiosResponse<GetAttachment_ServerResponse>> {
        const docs = new FormData()
        docs.append('uploadedFile', file)
        return this.client.post(`/attachments`, docs)
    }

    deleteAttachment(id: number): Promise<AxiosResponse> {
        return this.client.delete(`attachments/${id}`)
    }

    saveLogo({ file }: { file: File }): Promise<AxiosResponse> {
        const docs = new FormData()
        docs.append('uploadedFile', file)
        return this.client.post(`/attachments/logo`, docs)
    }

    deleteLogo(): Promise<AxiosResponse> {
        return this.client.delete(`attachments/logo`)
    }

    //-------- DIAGNOSTICS CONTROLLER
    getDiagnosticsInfo() {
        return this.client.get(`diagnostics/states`)
    }

    //-------- VIEWS
    getDeviceView(deviceId: NumericId): Promise<AxiosResponse<UniversalDeviceView>> {
        return this.client.get(`views/devices/${deviceId}`)
    }

    //-------- SENSORS CONTROLLER
    getKnownSensors(): Promise<AxiosResponse<string[]>> {
        return this.client.get(`/sensors/allknown`)
    }
}

export {
    MainGateway
}